<!-- eslint-disable -->
<template>
<v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <img id="bg" :src="bgUrl" class="center-cropped">
    <v-row
        align="center"
        justify="center"
    >

        <v-col
            cols="12"
            sm="6"
            lg="3"
            class="text-center"
        >
            <base-material-card
                id="cardlogin"
                color="warning"
                class="px-5 py-3"
                style="min-width:350px;"
                >
                <template v-slot:heading>
                    <div class="display-2 font-weight-light text-center">
                        <svg id="logo" viewBox="0 0 483 483"
                            style="enable-background:new 0 0 460 460;"
                            xml:space="preserve" width="128px" height="128px"
                            v-html="logoMethod">
                        </svg>
                        <h3>eOffice V3 BPDPKS</h3>
                    </div>
                    
                </template>
                <v-card-text>
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation

                    >
                        
                        <v-text-field
                            v-model="email"
                            :rules="[rules.required]"
                            label="E-mail"
                            id="whitetxt"
                        ></v-text-field>

                        <v-text-field
                            id="whitetxt"
                            v-model="password"
                            label="Password"
                            :type="showPass ? 'text' : 'password'"
                            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPass = !showPass"
                            :rules="[rules.required, rules.min]"
                        ></v-text-field>

                        <br>
                        
                        <v-btn
                            :disabled="!valid"
                            color="success"
                            class="mr-4"
                            @click="handleLogin()"
                        >
                        LOGIN
                        </v-btn>

<v-divider></v-divider>
<v-spacer></v-spacer>
                        <v-btn
                          text
                          color="warning"
                          class="mr-4 text-right"
                          @click="gottoReset()"
                        >
                          Lupa Password ?
                        </v-btn>

                    </v-form>
                </v-card-text>
            </base-material-card>

        </v-col>
    </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>
    
  </v-container>
</template>
<script>
  import secureStorage from '@/utils/secureStorage'
  import moment from 'moment'
  import Vivus from 'vivus'
  
  import logoData from './logoData'
  import { getListUsrPosActiveAll } from '@/api/hruserpositions'
  import { loginByUsername } from '@/api/login'
  import { getALLHROrganization } from '@/api/hrorganization'
  import { getListPositionsActive } from '@/api/hrpositions'
  import { getActiveUser } from '@/api/admin'
  import { getUserPosRoles } from '@/api/positionRoles'

  export default {
    name: 'login',
    data() {
      return {
        email: '',
        password: '',
        rules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 8 || 'Min 8 characters',
            emailMatch: () => ('The email and password you entered don\'t match'),
        },
        valid: false,
        showPass: false,
        vivus: '',
        toast: { show: false, color:'green', text:'', timeout: 2000 },
        isLoading: false
      }
    },
    computed: {
      logoMethod () {
        return logoData['Digitalizer']
      },
      bgUrl() {
        return require('@/assets/bg_1.jpg')
      }
    },
     mounted () {
       // console.log('loginpage')
      var token = secureStorage.getItem('jwt')
      if(token){
        this.$router.push('/')
      }

      this.startAnimation()
    },
    methods:{
        startAnimation () {
        this.vivus = new Vivus('logo', {
            duration: 400,
          forceRender: false
          }, function(element) {
            for (let item of element.el.children[0].children) {
              item.setAttribute('style', 'fill:white')
              item.setAttribute('style', 'fill:white')
            }
          }
        )
      },
      gottoReset(){
        this.$router.push('/reqforgotpwd')
      },
      handleLogin(e){
        const vm = this

        if (vm.password.length > 0) {
            vm.isLoading = true
            loginByUsername(vm.email, vm.password ).then(response => {
              // console.log('Well done!');
              // console.log('User profile', response.data.user);
              // console.log('User token', response.data.jwt);
              const user = response.data.user
              const token = response.data.jwt

              let is_admin = 0
              if(response.data.user.role.name == "Administrator"){
                  is_admin = 1
              }
              secureStorage.setItem('user',user)
              secureStorage.setItem('jwt',token)
              // vm.$store.state.user = response.data.user

              vm.isLoading = true
              getALLHROrganization().then(response => {
                secureStorage.setItem('orgall',response.data)
                
                vm.isLoading = false
              }).catch(error => {
                console.log(error)
                vm.isLoading = false
              })

              vm.isLoading = true
              getListPositionsActive().then(response => {
                secureStorage.setItem('posall',response.data)
    
                vm.isLoading = false
              }).catch(error => {
                // console.log(error)
                vm.isLoading = false
              })

              vm.isLoading = true
              getListUsrPosActiveAll().then(response => {
                secureStorage.setItem('userposall',response.data)
                secureStorage.setItem('lastupdate', moment())
                vm.isLoading = false
              }).catch(error => {
                // console.log(error)
                vm.isLoading = false
              })

              
              vm.isLoading = true
              getActiveUser().then(response => {
                // console.log('getActiveUser ',response.data)
                secureStorage.setItem('userall',response.data)
                vm.isLoading = false
              }).catch(error => {
                // console.log(error)
                vm.isLoading = false
              })

              vm.isLoading = true
              getUserPosRoles(token, user.id).then(response => {
                var userpos = response.data

                // console.log('userpos', userpos)
                secureStorage.setItem('userpos', userpos)
                if (userpos) {
                  var first = true
                  userpos.forEach (element => {
                    if (element.hrposition) {
                      var prefix = ''
                      if (element.prefix!='') {
                        prefix = element.prefix + ' '
                      }
                      if(first){
                        secureStorage.setItem('currRole', element)
                        // console.log('currRole', element)
                        first = false
                      }
                    }
                  })
                }
                vm.isLoading = false
                if (secureStorage.getItem('jwt') != null){
                  vm.$emit('loggedIn')

                  if(vm.$route.params.nextUrl != null){
                      vm.$router.push(vm.$route.params.nextUrl)
                  }else {
                      vm.$router.push('/')
                  }
                }
              }).catch(error => {
                // console.log(error)
                vm.isLoading = false
              })

            }).catch(err => {
                // console.log(err)
                vm.toast = {
                    show: true, color:'red', text: 'Username or Password was incorrect!', timeout: 2000
                }
                vm.isLoading = false
            })
        }
      }
    }
}
</script>
<style>
  #bg{
    position:fixed;
    top:0;
    left:0;
    z-index:0;
    min-width:100%;
    min-height:100%
  }
  .center-cropped{
    object-fit:none;
    object-position:center;
    height:100px;
    width:100px
  }

  #cardlogin{
    background-color: rgba(255, 255, 255, 0.8);
  }

  #whitetxt{
    color: black !important;
  }
</style>
<!-- eslint-enable -->